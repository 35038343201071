import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import axs from "@/service/AXS";
import axios from "axios";

import {messaging} from '@/service/firebase'


import profile from './profile'
import users from './users'
import single from './single'
import device from './device'
import notification from './notification'
import {getToken} from "firebase/messaging";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        showSorting: false,
        errorText: null,
        VAPID_KEY: 'BBZwqxhdsiLOmhIZXhV40lyvJbjKsoDu_P1iWeBTCpWzf2EgtHFLmQ8YSQ_BdF3kxTzmkRjbItjX8DkaHKFn1LY',
    },
    getters: {
        getErrorText(state) {
            return state.errorText
        },
    },
    mutations: {
        setErrorText(state, errorText) {
            state.errorText = errorText
        },
    },
    actions: {
        async login({commit, state}, user) {
            let res = await axs.post('/security/auth/email', user)
                .then(response => response)
                .catch(error => this.dispatch('updateError', error.response?.data?.detail ?? error))
            if (res) {
                // let isGranted = await Notification.requestPermission().then((permission) => {
                //     if (permission !== "granted") {
                //         this.dispatch('updateError', 'Включите уведомления')
                //         return false
                //     }
                //     return true
                // })
                //
                // if (!isGranted) return

                const accessToken = res.data.accessToken
                const refreshToken = res.data.refreshToken

                await getToken(messaging, {vapidKey: state.VAPID_KEY})
                    .then(deviceToken => {
                            this.dispatch('registrationDevice', {accessToken, deviceToken})
                            localStorage.setItem('firebase_token', deviceToken);
                        }
                    ).catch(error => console.log(error))

                localStorage.setItem('access_token', accessToken);
                localStorage.setItem('refresh_token', refreshToken);
                this.dispatch('uploadProfile')
            }
        },
        logout({commit, state}) {
            const url = process.env.VUE_APP_API_HOST.slice(0, -4)
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            return axios.delete(
                `${url}/notifications/fcmdevices/${localStorage.getItem('firebase_token')}`, {}, {
                    // headers: {
                    //     Authorization: `JWT ${localStorage.getItem('access_token')}`
                    // }
                }
            ).then(response => {
                localStorage.removeItem('firebase_token')
                this.dispatch('uploadProfile')
                router.push({
                    name: "Login"
                }).then().catch(console.log);
            }).catch(error => console.log(error))
        },
        registrationDevice({commit, state}, {accessToken, deviceToken}) {
            const url = process.env.VUE_APP_API_HOST.slice(0, -4)
            return axios.post(
                `${url}/notifications/fcmdevices `, {
                    'registration_id': deviceToken,
                    'type': 'web'
                }, {
                    headers: {
                        Authorization: `JWT ${accessToken}`
                    }
                }
            ).then(response => response.data.registrationId)
                .catch(error => this.dispatch('updateError', error.response.data.detail))
        },
        updateError({commit, state}, errorTxt) {
            commit('setErrorText', errorTxt)
        }
    },
    modules: {
        profile,
        users,
        single,
        device,
        notification,
    }
});

export default store;