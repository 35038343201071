import axs from "@/service/AXS";

export default {
    state: {
        users: null,
        usersPosition: null,
        queryOption: {
            searchTxt: '',
            ordering: {},
            filters: {}
        }
    },
    getters: {
        getUsers(state) {
            return state.users
        },
        getUsersPosition(state) {
            return state.usersPosition
        }
    },
    mutations: {
        setUsers(state, users) {
            state.users = users
        },
        setUsersPosition(state, usersPosition) {
            state.usersPosition = usersPosition
        },
    },
    actions: {
        uploadUsers({commit, state}, queryOption) {
            state.queryObject = queryOption
            let url = `/security/users?search=${queryOption.searchTxt}`

            if (Object.keys(queryOption.ordering).length) {
                let orderingString = Object.values(queryOption.ordering).join('&ordering=')
                url += `&ordering=${orderingString}`
            }

            if (Object.keys(queryOption.filters).length) {
                let filterString = Object.values(queryOption.filters).join('&')
                url += `&${filterString}`
            }

            if (queryOption.position) url += queryOption.position

            return axs.get(url)
                .then(response => {
                    commit('setUsers', response.data)
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.dispatch('logout');
                    } else {
                        console.error(error);
                        commit('setErrorText', 'Ошибка соединения с сервером')
                    }
                })
        },
        uploadUsersPosition({commit, state}) {
            return axs.get(`/security/users/positions`)
                .then(response => {
                    commit('setUsersPosition', response.data)
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.dispatch('logout')
                    } else {
                        console.error(error)
                        commit('setErrorText', 'Ошибка соединения с сервером')
                    }
                })
        }
    },
}