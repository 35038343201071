import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login"
import ChangePassword from "@/views/ChangePassword"
import UsersList from "@/views/users/List"
import UserSingle from "@/views/users/Single"

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
    },
    {
        path: "/",
        name: "UsersList",
        component: UsersList,
    },
    {
        path: "/:id",
        name: "UserSingle",
        component: UserSingle,
    }

];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('access_token');
    if (!token) {
        to.name === 'Login' ? next() : next({name: "Login"});
    } else {
        to.name === 'Login' ? next({name: 'UsersList'}) : next()
    }
});

export default router;