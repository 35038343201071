import axs from "@/service/AXS";

export default {
    state: {
        devices: null,
    },
    getters: {
        getDevices(state) {
            return state.devices
        },
    },
    mutations: {
        setDevices(state, devices) {
            state.devices = devices
        },
    },
    actions: {
        uploadDevices({commit, state}, userId) {
            return axs.get(`/security/devices?user=${userId}`)
                .then(response => {
                    commit('setDevices', response.data)
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.dispatch('logout')
                    } else {
                        console.error(error)
                        commit('setErrorText', error.response.data.detail);
                    }
                })
        },
        changeDevice({commit, state}, {action, deviceId, userId}) {
            return axs.post(`/security/devices/${deviceId}/${action}`, {})
                .then(response => {
                    this.dispatch('uploadDevices', userId)
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.dispatch('logout')
                    } else {
                        console.error(error)
                        commit('setErrorText', error.response.data.detail);
                    }
                })
        }
    },
}