import axs from "@/service/AXS";
import router from '@/router';

export default {
    state: {
        profile: null,
    },
    getters: {
        getProfile(state) {
            return state.profile
        },
    },
    mutations: {
        setProfile(state, profile) {
            state.profile = profile
        },
    },
    actions: {
        async uploadProfile({commit, state}) {
            const jwt = localStorage.getItem('access_token');
            if (!jwt) return commit('setProfile', null)

            return await axs.get('/security/profile')
                .then(response => {
                    let profile = response.data
                    if (!profile) {
                        console.log('GO TO LOGIN')
                        return commit('setProfile', null)
                    }

                    if (profile.needPasswordChange && router.currentRoute.name !== 'ChangePassword') {
                        router.push({name: 'ChangePassword'})
                    }

                    if (router.currentRoute.name === 'Login') {
                        router.push({name: 'UsersList'})
                    }

                    commit('setProfile', profile)
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.dispatch('logout')
                    } else {
                        console.error(error)
                        commit('setErrorText', error.response.data.detail);
                    }
                })
        },
        async changePassword({commit, state}, user) {
            return await axs.post('/security/profile/set_password', user)
                .then(response => console.log(response))
                .catch(error => console.log(error))
        }
    },
}