import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Moment from 'vue-moment'
import {getToken, onMessage} from 'firebase/messaging'
import {messaging} from './service/firebase';
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/scss/style.scss'

Vue.use(Vuelidate)
Vue.use(Moment);

Vue.config.productionTip = false

let app = new Vue({router, store, render: h => h(App)})
store.dispatch('uploadProfile').then(() => {
    app.$mount('#app')
});

// Запрос разрешений на уведомления
function requestNotificationPermission() {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            return registerServiceWorker();
        } else {
            console.log('Unable to get permission to notify.');
        }
    }).catch((err) => {
        console.log('Error requesting notification permission.', err);
    });
}

// Регистрация service-worker
function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js', {scope: "/firebase-cloud-messaging-push-scope"})
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
                return new Promise((resolve, reject) => {
                    if (registration.active) {
                        resolve(registration);
                    } else {
                        registration.addEventListener('updatefound', () => {
                            const installingWorker = registration.installing;
                            if (installingWorker == null) {
                                reject(new Error('Service worker installation failed'));
                            } else {
                                installingWorker.addEventListener('statechange', () => {
                                    if (installingWorker.state === 'activated') {
                                        resolve(registration);
                                    } else if (installingWorker.state === 'redundant') {
                                        reject(new Error('Service worker installation failed'));
                                    }
                                });
                            }
                        });
                    }
                });
            })
            .then((registration) => {
                console.log('Service Worker ready');
                return getToken(messaging, {
                    vapidKey: process.env.VUE_APP_VAPID_KEY, serviceWorkerRegistration: registration
                });
            })
            .then((token) => {
                if (token) {
                    console.log('FCM Token:', token);
                    // Send this token to your server to send notifications
                }
            })
            .catch((err) => {
                console.log('Service Worker registration failed:', err);
            });
    }
}

requestNotificationPermission();

// Уведомления при активной вкладке
onMessage(messaging, (payload) => {
    // console.log('Message received. ', payload);
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: '/notification-icon.png',
        badge: '/notification-badge.png',
    };

    const soundUrl = payload.data?.sound || '/sound.mp3';
    playSound(soundUrl)

    // Показываем уведомление
    new Notification(notificationTitle, notificationOptions);
});

function playSound(soundUrl) {
    const audio = new Audio(soundUrl);
    audio.play().catch(err => {
        console.log('Ошибка воспроизведения звука:', err);
    });
}

// Прослушивание сообщений от service-worker
navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data && event.data.type === 'playSound') {
        playSound(event.data.soundUrl);
    }
});